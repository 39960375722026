<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <el-row style="margin-top: 30px">
        <el-col :span="8" :offset="8">
          <el-form
            :model="addForm"
            :rules="rules"
            size="small"
            label-width="120px"
          >
            <el-form-item label="管理员手机号" prop="managerPhone">
              {{ addForm.managerPhone }}
            </el-form-item>
            <el-form-item label="车队简称" prop="shortname">
              {{ addForm.shortname }}
            </el-form-item>
            <el-form-item label="车队名称" prop="name">
              {{ addForm.name }}
            </el-form-item>
            <el-form-item label="联系电话" prop="telephone">
              {{ addForm.telephone }}
            </el-form-item>
            <el-form-item label="车队地址" prop="addrCode">
              {{ area }}
            </el-form-item>
            <el-form-item label="详细地址" prop="address">
              {{ addForm.address }}
            </el-form-item>
            <el-form-item label="道路运输许可" prop="transportlicencecode">
              {{ addForm.transportlicencecode }}
            </el-form-item>
            <el-form-item label="组织机构代码" prop="orgcode">
              {{ addForm.orgcode }}
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6" :offset="3">
          <img-upload
            :disabled="true"
            class="img-upload"
            title="企业营业执照"
            v-model="addForm.businesslicense"
          />
        </el-col>
        <el-col :span="6">
          <img-upload
            :disabled="true"
            class="img-upload"
            title="运营许可证"
            v-model="addForm.transportlicence"
          />
        </el-col>
        <el-col :span="6">
          <img-upload
            :disabled="true"
            class="img-upload"
            title="资料信息"
            v-model="addForm.filingvoucher"
        /></el-col>
      </el-row>
      <el-row style="margin-top: 25px">
        <el-col :offset="10">
          <el-button
            size="small"
            class="buttons"
            type="primary"
            @click="handlePass"
            >确认</el-button
          >
          <el-button
            size="small"
            type="danger"
            class="buttons"
            style="background: rgb(255, 89, 38); color: rgb(255, 255, 255)"
            @click="handleReject()"
            >驳回</el-button
          >
          <el-button size="small" class="buttons" @click="goBack"
            >取消</el-button
          >
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { codeToText } from "element-china-area-data";
import ImgUpload from "@/components/Img-upload.vue";
import { mapState } from "vuex";
export default {
  name: "particulars",
  data() {
    return {
      addForm: {
        managerPhone: "",
        shortname: "",
        name: "",
        telephone: "",
        address: "",
        addrCode: "",
        transportlicencecode: "",
        orgcode: "",
        businesslicense: "",
        transportlicence: "",
        filingvoucher: "",
      },
      rules: {
        /*username: [
          {
            required: true,
            message: "请输入账号",
            trigger: ["blur", "change"],
          },
        ],*/
        shortname: [
          {
            required: true,
            message: "请输入车队简称",
            trigger: ["blur", "change"],
          },
        ],
        name: [
          {
            required: true,
            message: "请输入车队名称",
            trigger: ["blur", "change"],
          },
        ],
        telephone: [
          {
            required: true,
            message: "请输入车队电话",
            trigger: ["blur", "change"],
          },
        ],
        addrCode: [
          {
            required: true,
            message: "请选择车队地址",
            trigger: ["blur", "change"],
          },
        ],
        address: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: ["blur", "change"],
          },
        ],
        transportlicencecode: [
          {
            required: true,
            message: "请输入道路运输许可",
            trigger: ["blur", "change"],
          },
        ],
        orgcode: [
          {
            required: true,
            message: "请输入组织机构代码",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  components: {
    ImgUpload,
  },
  computed: {
    ...mapState(["pendingCount"]),
  },
  created() {
    this.$http(
      "api1",
      "/api/biz/FleetInfo/info/" + this.$route.query.id,
      "get"
    ).then((res) => {
      for (let key in this.addForm) {
        this.addForm[key] = res.data.data[key];
      }
    });
  },
  computed: {
    area: function () {
      // `this` 指向 vm 实例
      let result = "";
      if (this.addForm.addrCode?.split(",")[0]) {
        result = codeToText[this.addForm.addrCode.split(",")[0]];
      }
      if (this.addForm.addrCode?.split(",")[1]) {
        result += "/" + codeToText[this.addForm.addrCode.split(",")[1]];
      }
      if (this.addForm.addrCode?.split(",")[2]) {
        result += "/" + codeToText[this.addForm.addrCode.split(",")[2]];
      }
      return result;
    },
  },
  mounted() {},
  methods: {
    handlePass() {
      this.$messageBox
        .confirm("确认" + "通过" + "该车队,是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(async () => {
          const { data } = await this.$http(
            "api1",
            "/api/biz/FleetInfo/auditAccept",
            "post",
            {
              fleetId: this.$route.query.id,
            }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.goBack();
          }
          this.getPendingCount();
        });
    },
    handleReject() {
      this.$messageBox
        .prompt("请输入驳回原因", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
        .then(async ({ value }) => {
          const { data } = await this.$http(
            "api1",
            "/api/biz/FleetInfo/auditRefuse",
            "post",
            { fleetId: this.$route.query.id, ...this.addForm, refuseReason: value }
          );
          if (data.code != 0) {
            return this.$message.error(data.msg);
          } else {
            this.$message.success("操作成功");
            this.goBack();
          }
          this.getPendingCount();
        })
        .catch(() => {});
    },
    async getPendingCount() {
      const res = await this.$http("api1", "/api/stat/pendingNum", "get");
      for (let item of this.pendingCount) {
        item.count = res.data[item.key];
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
